"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useContext, useEffect, useState } from "react";
import CardLink from "@/components/card-link";
import MobileNavMore from "@/components/global/mobile-nav-more";
import Icon from "@/components/icon";
import { headerArr, getNavLinks } from "@/constants/global";
import { isBurgerActiveContext } from "@/context/is-burger-active";
import { MobileContext } from "@/context/mobile-provider";
import useMobile from "@/hooks/use-mobile";
import { NavLinks } from "@/TS/Interfaces/global_interfaces";
function MobileNav({
  isPublic = false,
  slug = null
}: {
  isPublic: boolean;
  slug?: string;
}) {
  const {
    setIsPublic
  } = useContext(MobileContext);
  const [isActive, setIsActive] = useContext(isBurgerActiveContext);
  const path: string = usePathname();
  const isMobile = useMobile();
  const [isMore] = useState(false);
  const navLinks: Array<NavLinks> = getNavLinks(slug);
  useEffect(() => {
    setIsPublic(isPublic);
    // Lors du démontage du composant
    return (): void => {
      // passez d'une page privée à publique
      setIsPublic(true);
    };
  }, []);
  if (isMobile && !isPublic) {
    return <>
        {isMore && navLinks.length > 4 && <MobileNavMore links={navLinks.slice(4)}></MobileNavMore>}
        <div className={"fixed flex bottom-0 bg-white shadow-[0_-3px_6px_#0000001A] left-0 w-full h-16 z-50 justify-evenly items-center"} style={{
        padding: "env(safe-area-inset-bottom, 20px)"
      }}>
          {navLinks.map(({
          href,
          label,
          icon
        }, index) => {
          // {
          // TODO version finale, menu supplémentaire
          //  if (index > 4) return;
          //  if (index === 4) {
          //    return (
          //      <div
          //        key={label}
          //        onClick={() => setIsMore(!isMore)}
          //        className={`${path === href ? " text-primary font-bold" : ""} flex flex-col items-center hover:text-primary`}
          //        // onClick={}
          //      >
          //        <Icon size={32} icon={"menu"}></Icon>
          //      </div>
          //    );
          //  } else {
          return <Link key={label} href={href} className={`${path === href ? " text-primary font-bold" : ""} flex flex-col items-center hover:text-primary`}
          // onClick={}
          >
                <Icon size={24} icon={icon}></Icon>
                <p className="text-xs font-bold">{label}</p>
              </Link>;
          // {/*TODO version finale, menu supplémentaire*/}
          // }
        })}
        </div>
      </>;
  } else if (isMobile && isPublic && isActive) {
    return <div className={"flex z-50 left-0 fixed top-0 h-full pt-20 w-full bg-white"}>
        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col p-6 font-bold gap-6">
            {headerArr.map(({
            label,
            href
          }) => {
            return <Link key={label} onClick={() => setIsActive(false)} className={`${path === href ? "text-primary" : ""} font-bold text-sm hover:text-primary`} href={href}>
                  <p className={"text-lg"}>{label}</p>
                </Link>;
          })}
          </div>
          <div className="flex flex-col  bg-light-grey p-10 justify-self-center gap-2">
            <CardLink href={"/creer-un-club"} className={"h-24"} img={"/img/club/create.jpeg"} title={"Créer mon club"} />
            <CardLink className={"h-24 "} href={"/clubs"} img={"/img/club/join.jpeg"} title={"Rejoindre un club"} />
          </div>
        </div>
      </div>;
  }
}
export default MobileNav;